@use '../node_modules/angular-calendar/css/angular-calendar.css';
@use '@ctrl/ngx-emoji-mart/picker';
@use 'src/assets/scss/patterns';
@use 'src/assets/scss/sf-pro-display';

html {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  font-family: 'SF Pro Display', Arial, Helvetica, sans-serif !important;
  background-color: var(--theme-background) !important;
  width: 100vw;
  overflow-x: hidden;

  // global letter spacing for new font family
  letter-spacing: 0.3px;
}

/* Modal animation and styles start */
// preview modal styles start
.modal-content:has(.media-preview-modal-container) {
  background-color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  border: none !important;
}

// preview modal styles end
.modal-open .modal {
  background: rgba(255, 255, 255, 0.6);
}

.modal-content {
  border-radius: 16px !important;
  border: 1px solid #dfdfdf !important;

  @media (max-width: 650px) {
    position: fixed;
    bottom: -100%;
    left: 0;

    animation-name: modal-slide-in;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

@media (max-width: 650px) {
  /* Bottom Animation keyframes */
  @keyframes modal-slide-in {
    0% {
      bottom: -100%; /* Start off-screen */
    }
    100% {
      bottom: 0; /* Stop at position 0 */
    }
  }
}

.modal-backdrop.show {
  background: var(--milky-background);
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

/* Modal animation and styles end */

/* Labels start */
.pointer-cursor {
  cursor: pointer;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 30px !important;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 8px center;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.selected-content {
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0);
  border: solid 2px transparent;
  background: #eaeaea;
  height: 60px;
  padding: 15px;
  display: grid;
  grid-gap: 4px;
  place-content: center;
  transition: 0.3s ease-in-out all;
}

.selected-content h4 {
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
  color: var(--black-color);
  margin-bottom: 0;
  line-height: 1.4;

  @media (max-width: 499px) {
    font-size: 14px;
  }
}

.selected-label:hover {
  cursor: pointer;
}

.selected-label {
  position: relative;
  width: 100%;
}

.selected-label input {
  display: none;
}

.selected-label .icon {
  width: 20px;
  height: 20px;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 9px;
  transition: 0.3s ease-in-out all;
  transform: scale(1);
  z-index: 1;
}

.selected-label .icon:before {
  content: '\f00c';
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: 'Font Awesome 5 Free', serif;
  font-weight: 900;
  font-size: 12px;
  color: var(--black-color);
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-in-out all;
  transform: scale(2);
}

.selected-label input:checked + .icon {
  background: var(--black-color);
  color: var(--white-color);
  border-color: var(--black-color);
  transform: scale(1.2);
}

.selected-label input:checked + .icon:before {
  color: var(--white-color);
  opacity: 1;
  transform: scale(0.8);
}

.selected-label input:checked ~ .selected-content {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5);
  background: var(--black-color);
  color: var(--white-color);
  border-color: var(--black-color);

  h4 {
    color: #fff;
  }
}

.block-fx-shadow {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 4%) !important;
  border-radius: 16px;
  border: 1px solid #e6e9ec;
}

.content-heading,
.block-title {
  color: var(--black-color) !important;
  font-weight: bolder;
}

/* Labels end */

/* Forms styles start */
.form-control,
.input-group-text {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #dbdbdb !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--form-input-disable) !important;
  color: #898080 !important;
}

.input-group-text.readonly {
  background-color: var(--form-input-disable) !important;
  color: #898080 !important;
}

.search-input {
  border-radius: 20px;

  padding-left: 35px;
  background: #fafafa url('https://static-assets.vinlivt.de/icons/line-icons/search.png') no-repeat 9px 9px;
  background-size: 20px;
}

.search-input:focus {
  background: #fff url('https://static-assets.vinlivt.de/icons/line-icons/search.png') no-repeat 9px 9px;
  background-size: 20px;
}

/* Forms styles end */

/* Text start */

.font-size-h6 {
  font-size: 0.9rem;
}

.text-primary {
  color: var(--primary-color) !important;
}

.title-s20-w700 {
  font-size: 20px;
  font-weight: 700;
}

.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px !important;
}

.text-16-mobile {
  @media (max-width: 499px) {
    font-size: 16px;
  }
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.section-title,
.modal-title {
  font-size: 18px;
  color: var(--dark-gray-color);
}

.modal-body > p {
  color: var(--dark-gray-color);
}

a {
  color: #000 !important;
  font-weight: 600 !important;

  &:hover {
    color: var(--black-color) !important;
  }
}

/* Text end */

/* Buttons styles start */
.btn-mobile-full-w {
  @media (max-width: 499px) {
    width: 100%;
  }
}

.btn-white {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #fff !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background: var(--dark-gray-color) !important;
}

.btn-light-yellow {
  background: #f9fed3;
  border: 1px solid #f9fed3;
  color: #000;
}

.btn-light-yellow:hover {
  background: #000;
  border: 1px solid #fff;
  color: #fff;
}

.btn-action-secondary {
  align-items: center;
  border: 1px solid #000 !important;
  background: #000 !important;
  color: #fff !important;
}

.btn-circle-white {
  border-radius: 50% !important;
  border: 1px solid var(--black-color) !important;
  padding: 5px !important;
}

.btn-action-secondary:hover {
  background: #fff !important;
  color: #000 !important;

  img {
    filter: invert(97%) sepia(2%) saturate(0%) hue-rotate(308deg) brightness(116%) contrast(100%);
  }
}

.btn-action-secondary:disabled:hover {
  background: #000 !important;
  color: #fff !important;
}

.btn-white-outline {
  border: 1px solid #000;
  color: #000 !important;
  background: #fff;
}

.btn-white-outline:hover {
  color: #fff !important;
  background: #000;
}

.btn-white-outline:disabled {
  color: #000 !important;
  background: var(--dark-gray-color);
}

.btn-action {
  background-color: var(--black-color);
  color: var(--white-color);

  img {
    filter: invert(97%) sepia(2%) saturate(0%) hue-rotate(308deg) brightness(116%) contrast(100%);
  }
}

.btn-action:hover {
  background-color: var(--dark-gray-color);
  color: var(--white-color);
}

.btn-action-disabled,
.btn-action-disabled:hover {
  background-color: var(--white-color);
  color: var(--dark-gray-color);
}

/* Buttons styles end */

/* Tables styles start */
table tr:hover {
  background-color: #fcffe9 !important;
}

.full-width-table {
  margin-top: 30px;
  width: 100%;

  .td-ellipsis {
    position: relative;
  }

  // td:last-child {
  // padding-right: 0;
  // }
  .td-ellipsis:before {
    //content: '&nbsp;';
    visibility: hidden;
  }

  .td-ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
}

.rounded-corner-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px;

  td {
    border: 1px #787878;
    border-style: solid none;
    padding: 6px 14px;
  }

  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
  }

  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 28px;
    border-top-right-radius: 28px;
  }
}

/* Tables styles end */

/* Truncate blocks (ellipsis) start */
.parent-truncate {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.child-truncate {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-editor-wrap {
  white-space: pre-wrap;
}

mark {
  background-color: #e0f2fe;
  padding-left: 0;
  padding-right: 0;
}

/* Truncate blocks (ellipsis) end */

/* Block styles start */
.block,
.block-content,
.block-fx-shadow {
  border-radius: 12px !important;
}

.block-fx-shadow {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 4%) !important;
  border: 1px solid #e6e9ec;
}

.br-16 {
  border-radius: 16px !important;
  box-shadow: 0 8px 30px 2px rgba(0, 0, 0, 0.03) !important;
}

.gray-border {
  border: 1px solid #e7e6fe !important;
}

.bg-light-black {
  background: linear-gradient(135deg, #878787 0, #000000 100%) !important;
}

.bg-orange {
  background: #fcd34d !important;
}

.unread-message-circle {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: var(--red-alert);
  border: 1px solid #ffffff;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}

/* Block styles end */

/* Borders styles start */

.no-border-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.no-border-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 0 !important;
}

.border-left-top-right {
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}

/* Border styles end */

/* Bootstrap components styles start */
.css-control-input ~ .css-control-indicator {
  border-radius: 30px;
  width: 25px;
  height: 25px;
  top: 1px;
}

.css-checkbox.css-control-primary .css-control-input:checked ~ .css-control-indicator {
  background-color: #e1fc22;
  border-color: #e2e2e2;
}

.css-checkbox .css-control-input ~ .css-control-indicator::after {
  font-weight: 100;
  font-size: 10px !important;
}

.css-checkbox .css-control-input:checked ~ .css-control-indicator::after {
  color: #313131;
  top: 0px;
  font-size: 14px;
}

input[type='checkbox'] {
  vertical-align: middle;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  cursor: pointer;
}

.checkbox-label {
  display: contents !important;
}

.nav-tabs-alt .nav-item.show .nav-link,
.nav-tabs-alt .nav-link.active {
  box-shadow: inset 0 -2px var(--purple-color) !important;
}

.nav-tabs-alt .nav-link:focus,
.nav-tabs-alt .nav-link:hover {
  box-shadow: inset 0 -2px var(--purple-color) !important;
}

.nav-main a.active > i,
.nav-main a:focus > i,
.nav-main a:hover > i {
  color: #000000 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-main a.active,
.nav-main a:focus,
.nav-main a:hover {
  color: var(--primary-color) !important;

  i {
    color: var(--primary-color) !important;
  }
}

.nav-main a {
  color: #000 !important;
}

.nav-main i {
  color: #000 !important;
}

a.active {
  color: var(--primary-color) !important;

  i {
    color: var(--primary-color) !important;
  }
}

.nav-tabs-alt .nav-item.show .nav-link,
.nav-tabs-alt .nav-link.active {
  box-shadow: inset 0 -2px var(--primary-text-color) !important;
}

.nav-tabs-alt .nav-link:focus,
.nav-tabs-alt .nav-link:hover {
  box-shadow: inset 0 -2px var(--primary-text-color) !important;
}

// toastr styles
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  @media (max-width: 650px) {
    width: 94% !important;
  }
}

.toast-bottom-right {
  margin-bottom: 30px;
}

.toast-bottom-center {
  margin-bottom: 75px;
}

.toast-info {
  background-image: url('assets/icon/check.png');
  background-color: #000 !important;
  opacity: 0.85 !important;
  color: #fff !important;
  border-radius: 12px !important;
}

/* Bootstrap components styles end */

/* Add Scroll Bar like MacOS for problems Screen start */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 16px;
  background-color: #babac0;
}

*::-webkit-scrollbar-button {
  display: none;
}

/* Add Scroll Bar like MacOS for problems Screen end */

/* Gaps start */
.gap-1 {
  gap: 5px !important;
}

.gap-2 {
  gap: 10px !important;
}

.gap-3 {
  gap: 15px !important;
}

.gap-4 {
  gap: 20px !important;
}

.gap-5 {
  gap: 30px !important;
}

/* Gaps end */

/* Calendar styles start */
.calendar-appointment {
  span {
    color: #000 !important;
    font-weight: 600;
  }

  .cal-event {
    background-color: #e1fc22 !important;
    border: none;
  }
}

.calendar-event,
.calendar-reminder,
.calendar-termination,
.calendar-birthday {
  .cal-event {
    border: none;
  }

  span {
    color: white;
    font-weight: 600;
  }
}

.calendar-event {
  .cal-event {
    background-color: #8681fc !important;
  }
}

.custom-reminder,
.custom-termination,
.custom-birthday {
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(103%) contrast(101%);
}

/* Calendar styles end */

/* Tooltip class styles start */
.tooltipClass .tooltip-inner {
  background-color: #000000 !important;
}

.tooltipClass .tooltip-arrow {
  display: none;
}

/* Tooltip class styles end */

/* Paginator items styles start */
.page-item {
  margin: 0 3px;

  @media (max-width: 499px) {
    margin: 0 1px;
  }

  a {
    border-radius: 60px;
    width: 43px;
    text-align: center;
    line-height: 24px;
  }
}

.page-item:last-child .page-link {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

.page-item.active .page-link {
  background-color: #000;
  border-color: #000;
  color: #fff !important;
}

/* Paginator items styles end */

/* Progress styles start */
.ngx-spinner-overlay {
  .la-ball-atom > div:nth-child(1) {
    background: rgba(225, 252, 34, 1) !important;
  }
}

.progress-circle {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: conic-gradient(#000 0deg, #ededed 0deg);
  display: flex;
  justify-content: center;
  align-items: center;

  .desc-value {
    font-size: 10px;
    font-weight: 600;
    z-index: 9;
  }
}

.progress-circle::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
}

/* Progress styles end */

/* Badge styles start */
.badge {
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  padding: 8px 16px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  color: var(--white-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 99999px;
}

.badge-black {
  border-color: #808080 !important;
  background: #fff !important;
  color: #808080 !important;
}

.badge-black-active {
  border-color: #000 !important;
  background: #000 !important;
  color: #fff !important;
}

.badge-secondary {
  background-color: #ebedef !important;
  border-radius: 25px !important;
}

.badge-purple {
  background-color: #6e68fb;
  border-radius: 25px !important;
}

.badge-black-main {
}

.badge-black-main-active {
}

/* Badge styles send tart */

/* Fade in Animation start */
.fade-in-animation-msg-container-forwards {
  opacity: 0;
  animation-name: fade-in-animation;
  animation-duration: 0.6s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-animation-forwards {
  opacity: 0;
  animation-name: fade-in-animation;
  animation-duration: 0.3s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fade in Animation end */

/* Slide up Animation start */
.slide-up-animation {
  transform: translateY(100%);
  opacity: 0; /* Initially hide the message */
  animation-name: slide-up-animation;
  animation-duration: 0.3s;
  //animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes slide-up-animation {
  0% {
    transform: translateY(100%); /* Start below the container */
    opacity: 0; /* Initially hidden */
  }
  50% {
    opacity: 0.5; /* Stay hidden until halfway through the animation */
  }
  100% {
    transform: translateY(0); /* Slide up to its original position */
    opacity: 1; /* Fully visible at the end */
  }
}

/* Slide up Animation end */
