:root {
  --black-color: #000;
  --white-color: #fff;

  --primary-color: #e1fc22;

  --extra-light-gray-color: #dcdcdc;
  --super-light-gray-color: #cccccc;
  --light-border-color: #e7e6fe;
  --light-gray-color: #808080;
  --dark-gray-color: #666666;
  --medium-gray-color: #999999;
  --dark-black-color: #0d0d0d;

  --dark-100-color: #e6e6e6;
  --dark-300-color: #b3b3b3;

  --form-input-disable: #f4f4f4;

  --background-default: #fafafa;
  --hover-effect: #f1f0f0;

  --blue-color: #0ea5e9;
  --red-alert: #dc2626;
  --purple-color: #6e68fb;

  --light-orange-color: #ffedd5;
  --medium-orange-color: #fbbf24;
  --hard-orange-color: #f97316;
  --dark-orange-color: #fb923c33;

  --light-green-hover-button: #f0fdfa;
  --light-green-color: #d1fae5;
  --medium-green-color: #10b981;
  --dark-green-color: #065f46;
  --medium-dark-cyan: #0d9488;
  --medium-light-cyan: #6ee7b7;
  --mid-green-cyan: #34d399;
  --dark-green-button: #134e4a;
}
